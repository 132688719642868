import { render, staticRenderFns } from "./ForgotPass.vue?vue&type=template&id=5650600c&scoped=true&"
import script from "./ForgotPass.vue?vue&type=script&lang=js&"
export * from "./ForgotPass.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5650600c",
  null
  
)

export default component.exports